@charset "UTF-8";
/* CSS Document */
@import "./common/extend";
footer{ margin-top: 0; }
#top{
//    .k_visual{
//        position: relative;
//        .icon_scroll{
//            @include fo-min;
//            font-size: 1.5rem;
//            color: #fff;
//            padding-right: 90px;
//            position: absolute;
//            right: 5%;
//            bottom: 70px;
//            z-index: 3;
//            transform:rotate(90deg); 
//            &:after{
//                content: "";
//                display: inline-block;
//                width: 80px;
//                height: 1px;
//                background-color: #fff;
//                position: absolute;
//                bottom: 0.3em;
//                right: 0;
//            }
//            @include tablet{
//                font-size: 1.1rem;
//                padding-right: 40px;
//                right: 0;
//                bottom: 39px;
//                &:after{
//                    width: 30px;
//                }
//            }
//        }
//        img{
//            width: 100%;
//        }
//    }
    .top_concept{
        padding-bottom: 120px;
    }
    .contents_text{
        text-align: center;
    }
    .floor_info{
        padding-bottom: 60px;
    }
    .area_1f{        
        background: url("../img/bg_1f.png") no-repeat center;
        background-size: cover;
        width: 100%;
        padding: 9.4% 0;
    }
    .menu_2f{
        .menu_list{
            padding-bottom: 0;
        }
    }
    .area_2f{
        background: url("../img/bg_2f.png") no-repeat center;
        background-size: cover;
        width: 100%;
        padding: 9.4% 0;
    }
}

